<template>
  <v-navigation-drawer permanent :rail="$vuetify.display.mobile ? true : false">
    <v-list>
      <v-list-item
        prepend-icon="$calendar"
        data-cy="categoriesStats"
        to="/app/stats/categories"
        :active="categoriesStatsActive"
      >
        <v-list-item-title>Catégories</v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="$event"
        data-cy="eventsStats"
        to="/app/stats/events"
      >
        <v-list-item-title>Evénements</v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="$location"
        data-cy="locationsStats"
        to="/app/stats/locations"
      >
        <v-list-item-title>Lieux</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue';

import log from 'loglevel';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

let categoriesStatsActive = ref(false);

watch(router.currentRoute, (to) => {
  log.info('StatsMainDialog watch', to);
  if (to.path === '/app/stats' || to.path === '/app/stats/categories') {
    categoriesStatsActive.value = true;
  } else {
    categoriesStatsActive.value = false;
  }
});

onMounted(() => {
  log.info('StatsMainDialog mounted', router.currentRoute.value.path);
  if (
    router.currentRoute.value.path === '/app/stats' ||
    router.currentRoute.value.path === '/app/stats/categories'
  ) {
    categoriesStatsActive.value = true;
  } else {
    categoriesStatsActive.value = false;
  }

  store.commit('setAppNavTitle', 'Statistiques');
});
</script>

<style></style>
